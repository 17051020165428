import React from "react";
import axios from "axios";
import { API_ROOT } from "gatsby-env-variables";
import styles from "./map.module.scss";
import { useState } from "react";

const Map = () => {
  const [mapSrc, setMapSrc] = useState({});
  axios
    .get(API_ROOT + `/api/v1/settings`)
    .then((response) => {
      if (response && response.data && response.data.data) {
        setMapSrc({
          mapSrc:
            "https://maps.google.com/maps?q=" +
            response.data.data.latitude +
            "," +
            response.data.data.longitude +
            "&t=&z=14&ie=UTF8&iwloc=&output=embed",
        });
      }
    })
    .catch();

  return (
    <div className={styles.map}>
      <iframe
        width="100%"
        height="100%"
        id="gmap_canvas"
        title="mapFrame"
        src={mapSrc}
        frameBorder="0"
        scrolling="no"
        marginHeight="0"
        marginWidth="0"
      ></iframe>
      <a href="https://www.emojilib.com"></a>
    </div>
  );
};
export default Map;
