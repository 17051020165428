import React, { useState, useEffect, useRef } from "react";
import styles from "./form.module.scss";

const Inputs = (props) => {
  const { title, value, setAttribute } = props;
  const inputReference = useRef(null);
  const [hasFocus, setFocus] = useState(false);
  useEffect(() => {
    if (document.hasFocus() && inputReference.current.contains(document.activeElement) ) {
        setFocus(true);
    } 
  }, []);
  return (
    <div className={styles.form__inputs}>
      <label
        className={
          hasFocus ? styles.form__inputs_active : styles.form__inputs_deActive
        }
      >
        {title}
      </label>
      {title === "Message" ? (
        <textarea
          name={title}
          value={value}
          ref={inputReference}
          onFocus={() => setFocus(true)}
      onBlur={() => setFocus(false)}
          onChange={(e) => setAttribute(e.target.value)}
          required
        />
      ) : (
        <input
          type="text"
          name={title}
          value={value}
          ref={inputReference}
          onFocus={() => setFocus(true)}
      onBlur={() => setFocus(false)}
          onChange={(e) => setAttribute(e.target.value)}
          required
        />
      )}
    </div>
  );
};
export default Inputs;
