import React from "react";
import SEO from "../components/seo";
import { Container, Row } from "react-bootstrap";
import { toast } from "react-toastify";
import { isMobile } from "react-device-detect";
import Layout from "../components/academy-layout";
import NewNavbar from "../components/navbar";
import { API_ROOT } from "gatsby-env-variables";

import "../styles/pages/contact-us.scss";
import Map from "../components/contact-us/map";
import Form from "../components/contact-us/form";
import Axios from "axios";

const ContactUs = () => {
  let metaArr = [];
  let pageTitle = "Contact Us";
  Axios.get(API_ROOT + `/api/v1/seo/contact_us`).then((response) => {
    const data = response?.data?.data || [];
    data.map((e) => {
      if (e.type === "title") {
        pageTitle = e.value;
      } else {
        metaArr.push({
          name: e.type,
          content: e.value,
        });
      }
    });
  });

  return (
    <Layout>
      <SEO title={pageTitle} meta={metaArr} />
      <NewNavbar />

      <Map />
      <div className={`contact-us page-container`}>
        <Container>
          <Row>
            <div className={`${!isMobile && "col-lg-8"} contact-us__info`}>
              <ul>
                <li>
                  <p>
                    <img
                      src={require("../assets/images/icons/location-fill.svg")}
                    />
                    <span>Spitalackerstrasse 26, 3013 Bern Switzerland</span>
                  </p>
                </li>
                <li>
                  <a href="mailto:info@risklick.ch">
                    <img
                      src={require("../assets/images/icons/message-fill.svg")}
                    />
                    <span>info@risklick.ch</span>
                  </a>
                </li>
                <li>
                  <a href="tel:+41787814135">
                    <img
                      src={require("../assets/images/icons/phone-fill.svg")}
                    />
                    <span>+41 78 781 41 35</span>
                  </a>
                </li>
              </ul>
            </div>
            <Form />
          </Row>
        </Container>
      </div>
    </Layout>
  );
};
export default ContactUs;
