import React from "react";
import Axios from "axios";
import { useState } from "react";
import { toast } from "react-toastify";
import { API_ROOT } from "gatsby-env-variables";
import styles from "./form.module.scss";
import Inputs from "./inputs";

const Form = () => {
    
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    const requestObj = {
      name: name,
      email: email,
      message: message,
    };
    Axios.post(API_ROOT + `/api/v1/contacts`, requestObj)
      .then((response) => {
        toast.success("successfully sent.");
      })
      .catch((error) => {
        toast.error(`an error occured: ${error}`);
      });
  };
  return (
    <form onSubmit={handleSubmit} className={styles.form}>
        <Inputs title="Name" value={name} setAttribute={setName} />
        <Inputs title="Email" value={email} setAttribute={setEmail} />
        <Inputs title="Message" value={message} setAttribute={setMessage} />
      <button type="submit" className="form-submit" onClick={handleSubmit}>
        Login
      </button>
    </form>
  );
};
export default Form;
