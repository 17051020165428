import React from "react";
import { graphql, Link, StaticQuery } from "gatsby";
import { Container, Row, Col, Navbar, Nav } from "react-bootstrap";
import img from "../../assets/images/favicon.png";
import Helmet from "react-helmet";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "bootstrap/dist/css/bootstrap.css";
import Logo from "../../assets/images/logo.svg";
import Linkedin from "../../assets/images/logo/linkedin.png";
import Twitter from "../../assets/images/logo/twitter.png";
// import Facebook from "../../assets/images/logo/facebook.png";

import AnchorLink from "react-anchor-link-smooth-scroll";

const Layout = ({ children }) => (
  <div>
    <ToastContainer position="bottom-left" />
    <Helmet link={[
              {"rel": "icon", 
               "type": "image/png", 
               "href": img
              }
             ]} />
    <StaticQuery
      query={graphql`
        query SiteTitleQuery {
          site {
            siteMetadata {
              title
            }
          }
        }
      `}
      render={() => (
        <>
          <Container fluid className="xmain">
            <div className={"fullheight"}>
              {children}
            </div>
          </Container>
          <Container fluid className="px-0">
            <Row noGutters>
              <Col className="footer-col">
                <footer className={"academy-footer"}>
                  <Row className="top-footer">
                    <Col md={6}>
                      <img src={Logo} alt="" />
                    </Col>
                    <Col md={6}>
                      <Nav className="d-flex justify-content-end">
                        <Link to="/news" className="link-no-style">
                          <Nav.Link as="span" eventKey="news">
                            News
                          </Nav.Link>
                        </Link>
                        <Link to="/jobs" className="link-no-style">
                          <Nav.Link as="span" eventKey="jobs">
                            Jobs
                          </Nav.Link>
                        </Link>
                        <Link to="/team" className="link-no-style">
                          <Nav.Link as="span" eventKey="team">
                            About us
                          </Nav.Link>
                        </Link>
                        {/* <Link to="/about" className="link-no-style">
                          <Nav.Link as="span" eventKey="about">
                            Contact us
                          </Nav.Link>
                        </Link> */}
                      </Nav>
                    </Col>
                  </Row>
                  <Row className="middle-footer">
                    <Col md={4}>
                      <p>
                        Spitalackerstrasse 26, 3013 Bern <br />
                        Switzerland
                      </p>
                    </Col>
                    <Col md={4}>
                      <p className="text-center">
                        +41 78 781 41 35 <br />
                        support@risklick.ch
                      </p>
                    </Col>
                    <Col
                      md={4}
                      className="d-flex justify-content-end align-items-center"
                    >
                      <Link to="https://www.linkedin.com/company/risklick">
                        <img src={Linkedin} alt="" />
                      </Link>
                      {/* <Link to="https://linkedin.com">
                        <img src={Facebook} alt="" />
                      </Link> */}
                      <Link to="https://twitter.com/deduklick">
                        <img src={Twitter} alt="" />
                      </Link>
                    </Col>
                  </Row>
                  <Row className="bottom-footer">
                    <Col>
                      <p className="text-left">Copyright 2022</p>
                    </Col>
                    <Col md={{ span: "auto" }}>
                      <a href="/terms-and-conditions.pdf" download><p className="text-right">Terms and conditions</p></a>
                    </Col>
                    <Col md={{ span: "auto" }}>
                      <p className="text-right">Privacy policy</p>
                    </Col>
                  </Row>
                </footer>
              </Col>
            </Row>
          </Container>
        </>
      )}
    />
  </div>
);

export default Layout;
