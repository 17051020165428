import React, { useState, useEffect } from "react";
import { Container, Navbar, Nav, NavDropdown } from "react-bootstrap";
import { API_ROOT, PANEL_API_ROOT } from "gatsby-env-variables";
import axios from "axios";

import "./style.scss";

const NewNavbar = () => {
  const [getData, setCount] = useState(false);
  const [blogCategory, setBlogCategory] = useState("");

  useEffect(() => {
    if (getData === false) {
      setCount(true);
      axios
        .get(API_ROOT + `/api/v2/posts?type=show_header`)
        .then((response) => {
          let blogCategory = response?.data?.data || [];
          setBlogCategory(blogCategory);
        });
    }
  });

  return (
    <Navbar expand="lg" className="new-navbar" fixed="top">
      <Navbar.Brand href="/" className="new-navbar__brand">
        <img
          src={require("../../assets/images/risklick-logo.png")}
          alt="risklick logo"
        />
      </Navbar.Brand>

      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav
          className="me-auto"
          activeKey={
            typeof window !== "undefined" ? `${window.location?.pathname}` : "/"
          }
        >
          <Nav.Link href="/">
            <span>Home</span>
          </Nav.Link>
           <NavDropdown
            title="Products"
            className={
              typeof window !== "undefined"
                ? window.location?.pathname?.startsWith("/service") ||
                  window.location?.pathname?.startsWith("/products")
                  ? "active"
                  : ""
                : ""
            }
            id="basic-nav-dropdown"
          > 
           {/*  <NavDropdown.Item className="new-navbar__dropdown-section">
              <span>Products</span>
            </NavDropdown.Item> */}
            <NavDropdown.Item href="/products/deduklick">
              <span>Deduklick</span>
            </NavDropdown.Item>
            <NavDropdown.Item href="/#protocol-ai-benefits">
              <span>Protocol AI</span>
            </NavDropdown.Item>
            {/* <NavDropdown.Item className="new-navbar__dropdown-section">
              <span>Services</span>
            </NavDropdown.Item> */}
            {/* <NavDropdown.Item href="/service/meddev">
              <span>MedDev Intelligence</span>
            </NavDropdown.Item> */}
            {/* <NavDropdown.Item href="/service/risk-analyzer">
              <span>Trial Risk Analyzer</span>
            </NavDropdown.Item> */}
            {/* <NavDropdown.Item href="/service/design-studio">
              <span>Design Studio</span>
            </NavDropdown.Item> */}
          </NavDropdown> 
          
          {/* <Nav.Link href="/academy">
            <span>Academy</span>
          </Nav.Link> */}
          {/* <Nav.Link href="/team" className={
              typeof window !== "undefined"
                ? window.location?.pathname?.startsWith("/team")
                  ? "active"
                  : ""
                : ""
            }>
            <span>Team</span>
          </Nav.Link> */}
          {/* <Nav.Link href="/news/" className={
              typeof window !== "undefined"
                ? window.location?.pathname?.startsWith("/news")
                  ? "active"
                  : ""
                : ""
            }>
            <span>News</span>
          </Nav.Link> */}
          {/* <Nav.Link
            href="/jobs"
            className={
              typeof window !== "undefined"
                ? window.location?.pathname?.startsWith("/jobs")
                  ? "active"
                  : ""
                : ""
            }
          >
            <span>Jobs</span>
          </Nav.Link> */}

          <Nav.Link
            href={PANEL_API_ROOT}
            className="new-header__cta login"
          >
            Login
          </Nav.Link>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
};

export default NewNavbar;
